const IconWhatsapp = () => {
    return (
      <i>
        <svg
            width="24"
            height="24"
            viewBox="0 0 24 24"
            fill="none"
            xmlns="http://www.w3.org/2000/svg"
            >
            <path
                d="M0.827576 23.1754C1.04589 22.3443 1.23852 21.6284 1.43115 20.9124C1.70083 19.9024 1.97051 18.9052 2.27872 17.908C2.40714 17.4861 2.35577 17.1537 2.1503 16.7702C-0.405255 11.733 1.08442 5.6602 5.669 2.36172C12.5009 -2.54764 22.171 1.37729 23.6221 9.63626C24.7137 15.888 20.463 21.8585 14.1832 22.8557C11.846 23.2265 9.58581 22.9069 7.4412 21.8713C7.01741 21.6667 6.65784 21.654 6.22121 21.769C4.6288 22.2037 3.04924 22.6128 1.45683 23.0219C1.28989 23.0731 1.12294 23.1114 0.827576 23.1754ZM3.56292 20.4905C4.65449 20.2093 5.60479 19.9664 6.54226 19.6979C6.9532 19.5828 7.29994 19.6212 7.6852 19.8385C9.65002 20.9508 11.7689 21.3216 13.9906 20.9252C19.32 19.9792 22.7617 14.8652 21.6316 9.58512C20.6684 5.02096 16.1994 1.36451 10.7801 2.28501C4.0766 3.42286 0.763366 10.7358 4.24354 16.54C4.44902 16.8852 4.48754 17.1793 4.37196 17.55C4.08944 18.4833 3.85828 19.4294 3.56292 20.4905Z"
                fill="white"
            />
            <path
                d="M15.2874 17.1017C15.0177 17.0634 14.671 17.0378 14.3371 16.9483C12.5521 16.4497 10.934 15.6443 9.66261 14.3019C8.86641 13.4709 8.1601 12.5631 7.45379 11.6554C7.00432 11.0801 6.7218 10.4025 6.59338 9.67378C6.3879 8.52315 6.70895 7.52593 7.54368 6.69492C8.00599 6.24746 8.5582 6.29859 9.1104 6.41366C9.26451 6.45201 9.40577 6.65657 9.48282 6.82277C9.79103 7.52593 10.0864 8.2291 10.3818 8.94505C10.4973 9.20074 10.446 9.44365 10.279 9.66099C10.0864 9.91669 9.90661 10.1724 9.70114 10.4281C9.50851 10.6582 9.48282 10.8628 9.63693 11.1185C10.5487 12.6271 11.8072 13.7266 13.451 14.3914C13.7592 14.5192 13.9775 14.4553 14.183 14.2124C14.4141 13.9055 14.6838 13.6243 14.9021 13.3174C15.1076 13.0234 15.3516 12.9467 15.6727 13.0873C16.4047 13.4325 17.1366 13.7905 17.8686 14.1357C18.1512 14.2635 18.1768 14.5064 18.164 14.7749C18.0998 15.9 17.2651 16.8077 16.0708 17.0122C15.8139 17.0634 15.5828 17.0634 15.2874 17.1017Z"
                fill="white"
            />
        </svg>
      </i>
    );
  };
  
  export default IconWhatsapp;
  